import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import '../styles/pages.css'
import { Row, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import CtaCallNumbers from '../components/CtaCallNumbers'
import CtaLogosGov from '../components/CtaLogosGov'
import Seo from '../components/Seo'

export const query = graphql`
  query($id: ID!) {
    wpcontent {
      page(id: $id) {
        title
        content
        AcfCategoryFields {
          shortTitle
          introTitle
          introContent
          introImage {
            sourceUrl
          }
          related {
            ... on WPGraphQL_CustomRelated {
              id
              title
              uri
            }
            ... on WPGraphQL_Page {
              id
              title
              uri
            }
          }
        }
      }
    }
  }
`

const PageTemplate = ({ data }) => {
  // declare variables for quick access
  const maincontent = data.wpcontent.page.content
  const introTitle = data.wpcontent.page.AcfCategoryFields.introTitle
  const introImage = data.wpcontent.page.AcfCategoryFields.introImage.sourceUrl
  const itemsPostLinks = data.wpcontent.page.AcfCategoryFields.related

  return (
    <div className="pageColorTemplate">
      <Seo title={`${introTitle}`} />
      <Layout>
        <div
          className="bg-intro"
          style={{
            background: `url(${introImage})`,
          }}
        >
          <Row className="pageContentSubHeader">
            <Col className="col-12 nopadding">
              <h1
                className="like-h3 title-page mt-4"
                dangerouslySetInnerHTML={{ __html: introTitle }}
              />
            </Col>
          </Row>
        </div>
        <div className="pageMainContent">
          <div dangerouslySetInnerHTML={{ __html: maincontent }} />
        </div>
        <div className="contentListMenu">
          <a class="skip-main" href="#main" role="none">
            Skip to main content
          </a>
          {itemsPostLinks.map((item) => (
            <div className="BoxVerticalContainer">
              <Link
                to={
                  !item.uri.includes('sud_101/videos')
                    ? item.uri
                    : `/sud_101/videos/`
                }
                key={item.id}
                className="item"
                role="button"
              >
                <h5 dangerouslySetInnerHTML={{ __html: item.title }} />
              </Link>
            </div>
          ))}
        </div>
        <div className="space-50"></div>
        <CtaCallNumbers />
        <CtaLogosGov />
      </Layout>
    </div>
  )
}

export default PageTemplate
